import React, { useState } from "react";
import moment from 'moment';
import { Button } from 'react-bootstrap'
import { SendAmountModal } from "../SendAmountModal";
import PendingIcon from "../../resources/images/wallet/pending.svg";
import { nm__clienttype, nm_paymentstatus, nm_servicemonth } from "../../utils/codes";
import { toast } from "react-toastify";


export const Incentivepending = ({ incentivependingData, UserInstallmentFunc, allpendingcredit, formData, setLoading,user, userRoleArray }) => {
    const [getModalvalue, setModalvalue] = useState('')
    const [getModalitemdata, setModalitemdata] = useState('')
    // Account amount modal
    const [showSendAmountModal, setShowSendAmountModal] = useState(false);
    const closeSendAmountModal = () => setShowSendAmountModal(false);
    const openSendAmountModal = (getitems, getitemsData) => {
        setShowSendAmountModal(true)
        setModalvalue(getitems?.clientDetails)
        setModalitemdata(getitemsData)
    };

    incentivependingData?.list?.forEach(item => {
        const areBothTrue = item.userList.every(user => user.is_account_credited);
        if (areBothTrue) {
            item.clientDetails.bothAreTrue = "paid";
        } else {
            item.clientDetails.bothAreTrue = "pending";
        }
    });

      const handleOther = () =>{
           
                toast.error("You are not authorized to credit this incentive", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                } 
        

    return (
        <>
            {allpendingcredit?.length >= 1 ?
                <>
                    {allpendingcredit?.map((items, index) => (
                        <>
                            {/* {items?.clientDetails.bothAreTrue == "pending" ? */}
                            {items !== undefined &&
                                <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                                    <div className="p-3" style={{ backgroundColor: '#8F6F11' }}>
                                        <div className="d-flex flex-wrap justify-content-between gap-2">
                                            <div className="left-section info-box text-capitalize">
                                                <div className="label fs-5 fw-medium text-warning lh-sm">{items?.clientDetails?.client_name}</div>
                                                <div className="value text-white text-opacity-75 text-break">{items?.clientDetails.topic}</div>
                                            </div>
                                            <div className="right-section info-box text-capitalize text-end me-xl-5 pe-xl-5">
                                                <img src={PendingIcon} alt="PendingIcon" className="icon-img" style={{ width: '100px' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
                                            <div className="info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Month</div>
                                                <div className="value text-white text-opacity-75">{nm_servicemonth[items?.clientDetails.service_month]}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service in CRM</div>
                                                <div className="value text-white text-opacity-75 text-uppercase">{items?.clientDetails.service_id}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Zoho Client ID</div>
                                                <div className="value text-white text-opacity-75 text-uppercase">{items?.clientDetails.client_id}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Zoho Invoice ID</div>
                                                <div className="value text-white text-opacity-75 text-uppercase">{items?.clientDetails.invoice_id}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Billing ID</div>
                                                <div className="value text-white text-opacity-75 text-uppercase">{items?.clientDetails.billing_id}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Status</div>
                                                <div className="value text-white text-opacity-75">{nm_paymentstatus[items?.clientDetails.payment_status]}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Installment Approver</div>
                                                <div className="value text-white text-opacity-75">{items?.clientDetails.billing_manager}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client Type</div>
                                                <div className="value text-white text-opacity-75">{nm__clienttype[items?.clientDetails.client_type].replace(/\s+\S+$/, '')}</div>
                                            </div>
                                        </div>
                                        <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                                        {items?.userList?.length >= 1 ?
                                            <>
                                                {items?.userList?.map((itemsData, index2) => (
                                                    <div className="dark-theme-bg3 rounded-3 p-3 mt-1">
                                                        <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
                                                            <div className="col-12 col-sm-12 info-box text-capitalize">
                                                                <div className="d-flex align-items-center gap-2">
                                                                    <div className="user-img">
                                                                        <img src={require('../../resources/images/Avatar.png')} alt="User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px', minWidth: '45px' }} />
                                                                    </div>
                                                                    <div className="user-info text-truncate text-capitalize">
                                                                        <div className="user-name text-white fw-bold text-truncate">{itemsData?.name}</div>
                                                                        {/* <div className="opportunity-added fs-14 text-white text-wrap">Web Designer | <span className="ms-1">08-08-2024</span></div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="info-box text-capitalize">
                                                                <div className="label fs-14 text-warning">Role</div>
                                                                <div className="value text-white text-uppercase">{itemsData?.type}</div>
                                                            </div>
                                                            <div className="info-box text-capitalize ps-md-2 ps-lg-1">
                                                                <div className="label fs-14 text-warning">Requested Amount</div>
                                                                <div className="value text-white fw-bold text-uppercase">₹{itemsData?.redeem_amount}</div>
                                                            </div>
                                                            <div className="info-box text-capitalize ps-lg-2">
                                                                <div className="label fs-14 text-warning">Requested on</div>
                                                                <div className="value text-white text-uppercase">{itemsData?.requested_date != null ? moment(`${itemsData?.requested_date}`).format('DD-MM-YYYY') : "Yet to request"}</div>
                                                            </div>
                                                            <div className="info-box text-capitalize">
                                                                {userRoleArray.includes("ACM") ?
                                                                <div className="btn-wrapper ps-xl-2">
                                                                    <Button variant={`${itemsData?.requested_date != null ? "success" : "secondary"}`} className="text-center rounded-1 px-4 py-2" onClick={() => openSendAmountModal(items, itemsData)} style={{ minWidth: '180px' }}>
                                                                        <span className="d-block fs-5 fw-semibold text-white">Send ₹{itemsData?.redeem_amount}</span>
                                                                    </Button>
                                                                </div>
                                                                :
                                                                <div className="btn-wrapper ps-xl-2">
                                                                    <Button variant={`${itemsData?.requested_date != null ? "success" : "secondary"}`} className="text-center rounded-1 px-4 py-2" onClick={handleOther} style={{ minWidth: '180px' }}>
                                                                        <span className="d-block fs-5 fw-semibold text-white">Send ₹{itemsData?.redeem_amount}</span>
                                                                    </Button>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                            : "No Data Found."}
                                    </div>
                                </div>
                            }
                        </>
                    ))}
                </>
                : ""
            }
            {/* Redeem Amount Modal */}
            <SendAmountModal show={showSendAmountModal} close={closeSendAmountModal} modalValuedata={getModalvalue} Modalitemdata={getModalitemdata} UserInstallmentFuncModal={UserInstallmentFunc} formData={formData} setLoading={setLoading} user={user} setShowSendAmountModal={setShowSendAmountModal}/>
        </>
    )
}