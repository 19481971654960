import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import '../WalletSection/wallet.scss';
import { Button } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { callAxios, callCrmApi, timeDiffDaysHourMinSec } from "../utils/utils";
import { ManagementApprovalModal } from "./ManagementApprovalModal";
import { Opportunitykey } from "./Components/OpportunityKey";
import { Servicekey } from "./Components/Servicekey";
import { Duplicaterequest } from "./Components/Duplicaterequest";
import { Headerdetails } from "./Components/Headerdetails";
import { Installmentinfo } from "./Components/Installmentinfo";
import { Showloader } from "../WalletSection/Components/Loader";
import moment from 'moment';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";
import { environment } from "../environment";

import PhoneCallCard from "./Cards/PhoneCallCard";
import AppointmentCard from "./Cards/AppointmentCard";
import EmailCard from "./Cards/EmailCard";
import NoteCard from "./Cards/NoteCard";
import TaskCard from "./Cards/TaskCard";
import userData from '../utils/Users.json';
import { toast } from "react-toastify";
const OpportunityDetails = ({ setLoggedinUser, userRole, userRoleArray }) => {
    const [user, loading, error] = useAuthState(auth);
    let navigate = useNavigate();

    const {state} = useLocation()

    // console.log(state)
    
    const [tabContent, setTabContent] = useState('OpportunityDetails');
    const [LeadConversion, setLeadConversion] = useState('Notes');
    const [getLoading, setLoading] = useState(true)
    const [getHeaderdetails, setHeaderdetails] = useState('')
    const [getOpportunitykeydetails, setOpportunitykeydetails] = useState('')
    const [getServicekeydetails, setServicekeydetails] = useState('')
    const [getInstallmentCard, setInstallmentCard] = useState([])
    const [firstInstallmentData, setFirstInstallmentData] = useState([])
    const [duplicateRedeem, setDuplicateRedeem] = useState([])

      const [noteData, setNoteData] = useState([]);
       const [isFullLoading, setIsFullLoading] = useState(true);
        const [allCrmUser, setCrmUsers] = useState([]);

       const getAnnotation = async (opportunityId) => {
          // log__(opportunityId)
          setLoading(true)
        
            const annotationPayload = JSON.stringify({
              "operation":"fetchannotations",
              "leadid": "",
              "opportunityid":opportunityId
          })
        
         const annotationResponse = await callCrmApi(annotationPayload)
           
           const fullData = annotationResponse.data.data;
                     
            const TotalEle = fullData.length;
              for(let i=0; i<fullData.length;i++){
                // const getArrIndex = getIndexBySystemUserId(userData,fullData[i]._createdby_value);
    
                const nameObject = allCrmUser.find(item => item.systemuserid === fullData[i]._createdby_value);
                
                // if(getArrIndex > -1){
                //   fullData[i]['display_name'] = userData[getArrIndex].display_name;
                //   fullData[i]['image'] = userData[getArrIndex].image;
                 
                // }
                  fullData[i]['display_name'] = nameObject.yomifullname;
                  // fullData[i]['image'] = userData[getArrIndex].image;
                      if (i + 1 === fullData.length) {
                        ////log__(TotalEle);
                  // Last one.
                } else {
                  // Not last one.
                  ////log__(item.createdon, index, index+1,arrayObj[index+1].createdon)
                  const diff = timeDiffDaysHourMinSec(new Date(fullData[i].createdon),new Date(fullData[i+1].createdon));
                  const totalTime = timeDiffDaysHourMinSec(new Date(fullData[i].createdon),new Date(fullData[TotalEle-1].createdon));
                 
                  ////log__("Time Diff::",timeAgo)
                  fullData[i]['timeAgo'] = diff;
                  fullData[i]['totalTime'] = totalTime;
                  fullData[i]['opportunityId'] = opportunityId;
                  
        
        
                }
               
              }
              
             setNoteData(fullData);
             setLoading(false)
            
            
          };

          const fetchSystemUsers = async()=>{
                callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/co-ordination/fetch-actions`,{operation:"fetchsystemusers"},"post").then((users)=>{
                  setCrmUsers(users.data.users);
                })
              }

//    console.log(firstInstallmentData)
   useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
    
          setLoggedinUser(userInfo);
          opportunityDetailsdata()
          fetchSystemUsers();
        }
      }, [user, loading]);

    // View more show less functionality
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const opportunityDetailsdata = async (from=null) => {
      // console.log(from)
        setLoading(true);
        const postdata = {
            "topic": state?.topic,
            "serviceId": state?.serviceappliedid,
            "type": state?.type
        }
        try {
            const res = await callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/get-installment-details`, postdata, "post");
            const resData = res?.data;
            // console.log(resData)
            if (resData?.statusCode === 200) {
              if(from === "paymentApproved"){
                toast.success("Incentive request Approved!!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
              }
              if(from === "permissionError"){
                toast.error("permission denied!!", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
              }
                const getheaderdetailsdata = resData?.headerDetails;
                setHeaderdetails(getheaderdetailsdata)
                const getOpportunitydata = resData?.opportunityKeyDetails;
                setOpportunitykeydetails(getOpportunitydata)
                const getServicedata = resData?.serviceKeyDetails;
                setServicekeydetails(getServicedata)
                const getInstallmentdata = resData?.installmentCardData;
                setInstallmentCard(getInstallmentdata)
                setFirstInstallmentData(resData?.firstInstallmentApprovedData)
                setDuplicateRedeem(resData?.duplicateRedeem)
                setLoading(false);
            }
        } catch (error) {
            console.log("Error:", error);
            setLoading(false);
        }
    };

   

    const DateFormatter = (getData) => {
        const dateString = getData;
        const formattedDate = moment(dateString).format('DD-MM-YYYY');
        return formattedDate
    }

    const unixDateformatter = (getDate) => {
        const timestamp = getDate; 
        const formattedDate = moment.unix(timestamp).format('MMMM YYYY');
        return formattedDate
    }

    const handleNotes = (key) =>{
      // console.log(`Selected Tab: ${key}`);
      if(key === "LeadConversation"){
        getAnnotation(getInstallmentCard[0].opportunityid)
      }
      // log__("hit")
    }
   

    return (
        <div className="OpportunityDetails-page incentives-page py-3">
            <div className="container-fluid px-xxl-5">
                {getLoading ? <div className="loader-overlay" id="loader" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                height: '100vh',
                background: 'rgba(0, 0, 0, 0.8)',
                backdropFilter: 'blur(2px)'
            }}>
                <img src={require('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{ maxWidth: '200px', margin: "0 auto" }} />

                <div className="text-center w-50">
                    <p>Please wait...</p>
                </div>

            </div>
            :null}
                <div className="top-info-section mb-4">
                    <Headerdetails headerData={getHeaderdetails} getdashboardData={state?.getdashboardData} getallwalletUser={state?.getallwalletUser} activeIndex_={state?.activeIndex_}/>
                </div>
                <div className="tabs-wrapper">
                    <Tabs id="OpportunityDetails" activeKey={tabContent} onSelect={(k) => {setTabContent(k);handleNotes(k)}} className="dark-theme-bg2 rounded-top border-0 mb-3">
                        <Tab eventKey="OpportunityDetails" title="Opportunity Details">
                            <div className='tab-content-wrapper dark-theme-bg2 rounded-3 p-md-3'>
                                <div className="row g-3 mb-4">
                                    <Opportunitykey opportunityData={getOpportunitykeydetails} DateFormatefunc={DateFormatter} />
                                    <Servicekey serviceData={getServicekeydetails} DateFormatefunc={DateFormatter} getInstallmentCard={getInstallmentCard}/>
                                    <Duplicaterequest installmentData={getInstallmentCard} DateFormatefunc={DateFormatter} duplicateRedeem={duplicateRedeem}/>
                                </div>
                                {/* Installment Info */}
                                <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                                <Installmentinfo installmentData={getInstallmentCard} DateFormatefunc={unixDateformatter}  DateFormatefuncdata={DateFormatter} type={state?.type} opportunityDetailsdata={opportunityDetailsdata} firstInstallmentData={firstInstallmentData} by={user?.email} userRole={userRole} reapprove={state?.reapprove} setLoading={setLoading} user={user} topic={state?.topic} getOpportunitykeydetails={getOpportunitykeydetails} userRoleArray={userRoleArray}/>
                            </div>
                        </Tab>
                        <Tab eventKey="LeadConversation" title="Lead Conversation">
                            <div className='tab-content-wrapper'>
                                <div className="row g-3">
                                    <div className="col-xl-12">
                                        <div className="dark-theme-bg2 h-100 rounded-3 overflow-hidden">
                                            <Tabs id="LeadConversion" activeKey={LeadConversion} onSelect={(k) => setLeadConversion(k)} className="dark-theme-bg2 rounded-top">
                                                <Tab eventKey="Notes" title="Notes">
                                                    <div className="tab-content-wrapper d-flex flex-column gap-4 p-3 p-md-4">
                                                   
                                                    {
                        noteData.length > 0 ?

                        noteData?.map((item, index) => (
                            <div className="d-flex flex-wrap flex-md-nowrap gap-2">
                             {item.activitytypecode === "phonecall"?
                           
                           <PhoneCallCard item={item} clientName={state.clientName} />
                           :
                           item.activitytypecode === "appointment"?
                           <AppointmentCard item={item} clientName={state.clientName}  userData={userData} />
                           :
                           item.activitytypecode === "email"?
                           <EmailCard item={item} clientName={state.clientName}  userData={userData} />
                           :
                           item.activitytypecode === "task"?
                           <TaskCard item={item} clientName={state.clientName} />
                           :
                           item.objecttypecode === "lead" || item.objecttypecode === "opportunity"?
                           <NoteCard item={item} clientName={state.clientName} />
                           :
                         null}
                        </div>
                        
                        ))
                      :"No data found"
                      }
                                                        
                                                    </div>
                                                </Tab>
                                                {/* <Tab eventKey="CallRecordings" title="Call Recordings">
                                                    <div className='tab-content-wrapper d-flex flex-column gap-4 p-3 p-md-4'>
                                                        No data here to display..
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="SheelaAnalysis" title="Sheela's Analysis">
                                                    <div className='tab-content-wrapper d-flex flex-column gap-4 p-3 p-md-4'>
                                                        No data here to display
                                                    </div>
                                                </Tab> */}
                                            </Tabs>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(OpportunityDetails);

