import React from "react";
import { Accordion, Card, Button, useAccordionButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSdm } from "../Context/SdmContext";
import { toast } from "react-toastify";
import { convertReadable, isDateEqual } from "../utils/utils";


const CustomDayViewContent = (props) => {
    const { sdmListing, setShowWorkUpdateModal, todayDate, sdmData, isWeekOff } = useSdm();  // Use the context hook
    // console.log('Custom Data from Context:', sdmListing);

    const hasEvents = sdmListing && sdmListing.length > 0;

    const openModal = () => {
        if (isWeekOff) {
            toast.error('You can not add work update on weekends', {
                position: "top-right", // Position of the toast
                autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                hideProgressBar: false,     // Hide the progress bar
                closeOnClick: true,        // Close the toast when clicked
                pauseOnHover: true,        // Pause the toast when hovering over it
                draggable: true,           // Make the toast draggable
            });
            return false
        }
        if (sdmData.length === 0) {
            toast.error('No billing found on given date. you can not add work report', {
                position: "top-right", // Position of the toast
                autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                hideProgressBar: false,     // Hide the progress bar
                closeOnClick: true,        // Close the toast when clicked
                pauseOnHover: true,        // Pause the toast when hovering over it
                draggable: true,           // Make the toast draggable
            });
        } else {
            setShowWorkUpdateModal(true)
        }

    }
    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log('Accordion toggled!')
        );

        return (
            <div className="custom-bg" onClick={decoratedOnClick}>
                {children}
            </div>
        );
    }
    const htmlSdm = sdmListing && sdmListing.length > 0 && sdmListing.map((item, index) => {
        if (item?.client_intraction === "no") {
            return (
                <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                    <Card.Header className="position-relative p-3 pe-5" style={{ backgroundColor: '#7D2930' }}>
                        <CustomToggle eventKey={index}>
                            <div className="row gx-2 gy-0">
                                <div className="col-md-4 left-section">
                                    <div className="client-name text-white fs-6 fw-medium text-capitalize">{item.clientName}
                                        {/* <span className="feedback_type text-warning ms-2"><i className="bi bi-exclamation-triangle-fill"></i></span> */}
                                    </div>
                                </div>
                                <div className="col-md-4 middle-section text-md-center">
                                    <div className="interaction-status text-white fs-6 fw-medium text-capitalize">Total Incentive: ₹{item?.total_incentive}</div>
                                </div>
                                <div className="col-md-4 right-section">
                                    <div className="fs-14 d-flex flex-wrap align-items-center justify-content-md-end row-gap-1 column-gap-2 pe-3">
                                        {isDateEqual(item?.date, item?.createdAt) ?
                                            <span className="update-timing text-green text-uppercase">On Time</span>
                                            :
                                            <span className="update-timing text-warning text-uppercase">Late Update</span>
                                        }

                                        {isDateEqual(item?.date, item?.createdAt) ?
                                            <span className="text-white">Updated on: {convertReadable(item?.date)}</span>
                                            :
                                            <span className="text-white">Updated on: {convertReadable(item?.createdAt)}</span>
                                        }

                                    </div>
                                </div>
                            </div>
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index}>
                        <Card.Body className="p-3">
                            <div className="row g-3 mb-4">
                                <div className="col-md-7 col-xl-8">
                                    <div className="info-box">
                                        <div className="client-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.clientName}</div>
                                        <div className="client-company text-light text-opacity-50 fs-14 text-capitalize mb-2">
                                            <span className='client-company'>{item?.opportunity_belongs_to}</span>
                                            <span className='client-country fs-14'>{item?.opportunity_country}</span>
                                        </div>
                                        <div className="fs-6 text-white text-capitalize d-flex flex-wrap gap-2">
                                            <span className='work-type'>{item?.clientType?.replace(/\s+\S+$/, '')}</span> |
                                            <span className='text-uppercase'>{item?.service_id}</span> |
                                            <span>{item?.opportunity_type}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-xl-4">
                                    {item?.front_face ?
                                        <div className="info-box">
                                            <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.front_face}</div>
                                            {/* <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.frontFace?.email}</div> */}
                                            <div className="fs-6 text-white text-capitalize">
                                                <span className='resource-designation'>Front Face</span>
                                                {/* <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.front_end_developer?.hourConsumed} hrs / {item?.front_end_developer?.hourAssigned} hrs</span> */}
                                            </div>
                                        </div>
                                        : null}


                                </div>
                            </div>
                            {Object.entries(item?.front_end_developer).length > 0 || Object.entries(item?.backen_developer).length > 0 || Object.entries(item?.designer).length > 0 || Object.entries(item?.tester).length > 0 ?
                                <div className="row g-3 mb-4">
                                    {item?.front_end_developer && item?.front_end_developer?.name ?
                                        <div className="col-xl-3 col-lg-4 col-sm-6">

                                            <div className="info-box dark-theme-bg3 rounded-3 p-3">
                                                <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.front_end_developer?.name}</div>
                                                <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.front_end_developer?.email}</div>
                                                <div className="fs-6 text-white text-capitalize">
                                                    <span className='resource-designation'>FrontEnd</span>
                                                    <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.front_end_developer?.hourConsumed} hrs / {item?.front_end_developer?.hourAssigned} hrs</span>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                    {item?.backen_developer && item?.backen_developer?.name ?
                                        <div className="col-xl-3 col-lg-4 col-sm-6">

                                            <div className="info-box dark-theme-bg3 rounded-3 p-3">
                                                <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.backen_developer?.name}</div>
                                                <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.backen_developer?.email}</div>
                                                <div className="fs-6 text-white text-capitalize">
                                                    <span className='resource-designation'>Backend</span>
                                                    <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.backen_developer?.hourConsumed} hrs / {item?.backen_developer?.hourAssigned} hrs</span>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                    {item?.designer && item?.designer?.name ?
                                        <div className="col-xl-3 col-lg-4 col-sm-6">

                                            <div className="info-box dark-theme-bg3 rounded-3 p-3">
                                                <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.designer?.name}</div>
                                                <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.designer?.email}</div>
                                                <div className="fs-6 text-white text-capitalize">
                                                    <span className='resource-designation'>Designer</span>
                                                    <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.designer?.hourConsumed} hrs / {item?.designer?.hourAssigned} hrs</span>
                                                </div>
                                            </div>

                                        </div>
                                        : null}


                                    {item?.tester && item?.tester?.name ?
                                        <div className="col-xl-3 col-lg-4 col-sm-6">

                                            <div className="info-box dark-theme-bg3 rounded-3 p-3">
                                                <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.tester?.name}</div>
                                                <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.tester?.email}</div>
                                                <div className="fs-6 text-white text-capitalize">
                                                    <span className='resource-designation'>Tester</span>
                                                    <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.tester?.hourConsumed} hrs / {item?.tester?.hourAssigned} hrs</span>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                </div>
                                : null}
                                 <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                                 <div className="info-box fs-6 mb-4">
                                <div className="label text-white fw-bold mb-3">Communication Overview</div>
                                <div className="row g-3 mb-4">
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="info-box fs-14">
                                            <div className="label text-warning">Did you intract with the client today?</div>
                                            <div className="value text-white text-capitalize">{item?.client_intraction}</div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            {item?.feedback ?
                                <div className="info-box fs-6 mb-3">
                                    <div className="label text-warning mb-1">Reason</div>
                                    <div className="reason-description text-white fs-14">
                                        {item?.feedback}
                                    </div>
                                </div>
                                : null}
                        </Card.Body>
                    </Accordion.Collapse>
                </div>
            )
        } else {
            return (
                <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                    <Card.Header className="position-relative p-3 pe-5" style={{ backgroundColor: '#1C5138' }}>
                        <CustomToggle eventKey={index}>
                            <div className="row gx-2 gy-0">
                                <div className="col-md-4 left-section">
                                    <div className="client-name text-white fs-6 fw-medium text-capitalize">{item?.clientName}
                                        {item?.interaction_status === "concerned" ?
                                           <OverlayTrigger placement="top"
                                           overlay={
                                             <Tooltip>There are some concerns regarding this client update.</Tooltip>
                                           }
                                         >
                                             <span className="feedback_type text-warning ms-2"><i className="bi bi-exclamation-triangle-fill"></i></span>
                                         </OverlayTrigger>
                                            : null}
                                    </div>
                                </div>
                                <div className="col-md-4 middle-section text-md-center">
                                    <div className="interaction-status text-white fs-6 fw-medium text-capitalize">Total Incentive: ₹{item?.total_incentive}</div>
                                </div>
                                <div className="col-md-4 right-section">
                                    <div className="fs-14 d-flex flex-wrap align-items-center justify-content-md-end row-gap-1 column-gap-2 pe-3">
                                        {isDateEqual(item?.date, item?.createdAt) ?
                                            <span className="update-timing text-green text-uppercase">On Time</span>
                                            :
                                            <span className="update-timing text-warning text-uppercase">Late Update</span>
                                        }
                                        {isDateEqual(item?.date, item?.createdAt) ?
                                            <span className="text-white">Updated on: {convertReadable(item?.date)}</span>
                                            :
                                            <span className="text-white">Updated on: {convertReadable(item?.createdAt)}</span>
                                        }

                                    </div>
                                </div>
                            </div>
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index}>
                        <Card.Body className="p-3">
                            <div className="row g-3 mb-4">
                                <div className="col-md-7 col-xl-8">
                                    <div className="info-box">
                                        <div className="client-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.clientName}</div>
                                        <div className="client-company text-light text-opacity-50 fs-14 text-capitalize mb-2">
                                            <span className='client-company'>{item?.opportunity_belongs_to}</span>
                                            <span className='client-country fs-14'>{item?.opportunity_country}</span>
                                        </div>
                                        <div className="fs-6 text-white text-capitalize d-flex flex-wrap gap-2">
                                            <span className='work-type'>{item?.clientType?.replace(/\s+\S+$/, '')}</span> |
                                            <span className='text-uppercase'>{item?.service_id}</span> |
                                            <span>{item?.opportunity_type}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-xl-4">
                                    {item?.front_face ?
                                        <div className="info-box">
                                            <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.front_face}</div>
                                            {/* <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.front_face?.email}</div> */}
                                            <div className="fs-6 text-white text-capitalize">
                                                <span className='resource-designation'>Front Face</span>
                                                {/* <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.front_end_developer?.hourConsumed} hrs / {item?.front_end_developer?.hourAssigned} hrs</span> */}
                                            </div>
                                        </div>
                                        : null}


                                </div>
                            </div>
                            {Object.entries(item?.front_end_developer).length > 0 || Object.entries(item?.backen_developer).length > 0 || Object.entries(item?.designer).length > 0 || Object.entries(item?.tester).length > 0 ?
                                <div className="row g-3 mb-4">
                                    {item?.front_end_developer && item?.front_end_developer?.name ?
                                        <div className="col-xl-3 col-lg-4 col-sm-6">

                                            <div className="info-box dark-theme-bg3 rounded-3 p-3">
                                                <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.front_end_developer?.name}</div>
                                                <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.front_end_developer?.email}</div>
                                                <div className="fs-6 text-white text-capitalize">
                                                    <span className='resource-designation'>FrontEnd</span>
                                                    <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.front_end_developer?.hourConsumed} hrs / {item?.front_end_developer?.hourAssigned} hrs</span>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                    {item?.backen_developer && item?.backen_developer?.name ?
                                        <div className="col-xl-3 col-lg-4 col-sm-6">

                                            <div className="info-box dark-theme-bg3 rounded-3 p-3">
                                                <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.backen_developer?.name}</div>
                                                <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.backen_developer?.email}</div>
                                                <div className="fs-6 text-white text-capitalize">
                                                    <span className='resource-designation'>Backend</span>
                                                    <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.backen_developer?.hourConsumed} hrs / {item?.backen_developer?.hourAssigned} hrs</span>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                    {item?.designer && item?.designer?.name ?
                                        <div className="col-xl-3 col-lg-4 col-sm-6">

                                            <div className="info-box dark-theme-bg3 rounded-3 p-3">
                                                <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.designer?.name}</div>
                                                <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.designer?.email}</div>
                                                <div className="fs-6 text-white text-capitalize">
                                                    <span className='resource-designation'>Designer</span>
                                                    <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.designer?.hourConsumed} hrs / {item?.designer?.hourAssigned} hrs</span>
                                                </div>
                                            </div>

                                        </div>
                                        : null}


                                    {item?.tester && item?.tester?.name ?
                                        <div className="col-xl-3 col-lg-4 col-sm-6">

                                            <div className="info-box dark-theme-bg3 rounded-3 p-3">
                                                <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.tester?.name}</div>
                                                <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.tester?.email}</div>
                                                <div className="fs-6 text-white text-capitalize">
                                                    <span className='resource-designation'>Tester</span>
                                                    <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.tester?.hourConsumed} hrs / {item?.tester?.hourAssigned} hrs</span>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                </div>
                                : null}
                                 <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                            <div className="info-box fs-6 mb-4">
                                <div className="label text-white fw-bold mb-3">Communication Overview</div>
                                <div className="row g-3 mb-4">
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="info-box fs-14">
                                            <div className="label text-warning">Did you intract with the client today?</div>
                                            <div className="value text-white text-capitalize">{item?.client_intraction}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="info-box fs-14">
                                            <div className="label text-warning">Mode of communication</div>
                                            <div className="value text-white text-capitalize">{item?.communication_mode}</div>
                                        </div>
                                    </div>
                                    {item?.communication_medium.length > 0 ?
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="info-box fs-14">
                                            <div className="label text-warning">Medium of communication</div>
                                            <div className="value text-white text-capitalize">{item?.communication_medium}</div>
                                        </div>
                                    </div>
                                    :null}
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="info-box fs-14">
                                            <div className="label text-warning">Tool of communication</div>
                                            <div className="value text-white text-capitalize">{item?.communication_tool}</div>
                                        </div>
                                    </div>
                                    {item?.communication_mode === "verbal" ?
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="info-box fs-14">
                                            <div className="label text-warning">Communication duration</div>
                                            <div className="value text-white text-capitalize">{item?.communication_duration}  {item?.communication_unit}</div>
                                        </div>
                                    </div>
                                    :null}
                                </div>
                                
                            </div>
                            <div className="info-box fs-6 mb-4">
                                <div className="label text-white fw-bold mb-3">Feedback</div>
                                <div className="row g-3 mb-4">
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="info-box fs-14">
                                            <div className="label text-warning">How was the client interaction?</div>
                                            {item?.interaction_status && item?.interaction_status === "concerned" ?
                                            <div className="value text-danger text-capitalize">Concerned</div>
                                            :
                                           
                                                <div className="value text-green text-capitalize">Positive</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="info-box fs-14">
                                        <div className="label text-warning">Resource responsible for client  {item?.interaction_status === "concerned"?"concerned":"happiness"}</div>
                                            <div className="value text-white text-capitalize">{item?.resource_name}</div>
                                        </div>
                                    </div>
                                    
                                </div>
                               
                                
                            </div>
                            {item?.feedback ?
                                <div className="info-box mb-4">
                                    {item?.interaction_status === "concerned" ?
                                    <div className="label fs-14 text-warning mb-1">Reason for concerned feedback <span className="text-danger">(Provide a clear and detailed summary of your discussion with the client, highlighting key points, concerns addressed, solutions provided and their response.)</span></div>
                                    :
                                    <div className="label fs-14 text-warning mb-1">Reason for positive feedback</div>
                                    }
                                    <div className="feedback-description text-white fs-14">
                                        {item?.feedback}
                                    </div>
                                </div>
                                : null}
                            {item?.sdm_concerns ?
                                <div className="info-box mb-4">
                                    <div className="label fs-14 text-warning mb-1">Any specific challenges or concerns you'd like to highlight?</div>
                                    <div className="feedback-description text-white fs-14">
                                        {item?.sdm_concerns}
                                    </div>
                                </div>
                                : null}
                            {item?.pending_action ?
                                <div className="info-box mb-3">
                                    <div className="label fs-14 text-warning mb-1">Follow-up</div>

                                    <div className="value text-green text-capitalize mb-1">{item?.pending_action}</div>
                                    {item?.pending_action === "yes" ?
                                        <div className="feedback-description text-white fs-14">
                                            {item?.pending_action_notes}
                                        </div>
                                        : null}
                                </div>
                                : null}
                        </Card.Body>
                    </Accordion.Collapse>
                </div>
            )
        }

    })
    return (
        <div className="DayViewLayout h-100">


            {hasEvents ? (
                <Accordion defaultActiveKey="0" alwaysOpen className="work-cards-wrapper d-flex flex-column gap-3">
                    {htmlSdm}

                </Accordion>
            ) : (
                <div className="content-wrapper h-100 border border-secondary rounded-3 d-flex flex-column justify-content-center flex-fill text-center overflow-auto p-3">
                    <div className="icon text-center mb-3 mb-md-4">
                        <i className="bi bi-exclamation-octagon-fill fs-1 lh-1" style={{ color: '#acaa54' }}></i>
                    </div>
                    <div className="description text-light fw-medium text-center lh-sm mb-4">
                        No work update submitted <span style={{ color: '#e6ef13' }}>{convertReadable(todayDate)}</span>. SDM incentives require daily updates for each billing.<br />
                        Incentives will be released after Sheela receives all updates for the billing hours.
                    </div>
                    
                </div>
            )}
        </div>
    );
};

export default CustomDayViewContent;
