import React from "react";
import { createPlugin } from '@fullcalendar/core';
import { Card, Button, Accordion, useAccordionButton } from 'react-bootstrap';
import '../SDMWorkUpdate/DailyWorkUpdate.scss';

import CustomDayViewContent from "./CustomDayViewContent";

const CustomDayViewPlugin = createPlugin({
    views: {
        customDay: {
            content: (props) => {
                return <CustomDayViewContent {...props} />; 
                // const sdmListing = useSdm();
                // console.log('Custom Data from Context:', sdmListing);

                // function CustomToggle({ children, eventKey }) {
                //     const decoratedOnClick = useAccordionButton(eventKey, () =>
                //         console.log('totally custom!'),
                //     );

                //     return (
                //         <div className="custom-bg" onClick={decoratedOnClick}>
                //             {children}
                //         </div>
                //     );
                // }

                // // Check if there are events
                // const hasEvents = props.events && props.events.length > 0;

                // // Render your custom HTML content
                // return (
                //     <div className="DayViewLayout h-100">
                //         {hasEvents ? (
                //             <Accordion defaultActiveKey="0" alwaysOpen className="work-cards-wrapper d-flex flex-column gap-3">
                //                 <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                //                     <Card.Header className="position-relative p-3" style={{ backgroundColor: '#7D2930' }}>
                //                         <CustomToggle eventKey="0">
                //                             <div className="row gx-2 gy-0">
                //                                 <div className="col-md-4 left-section">
                //                                     <div className="client-name text-white fs-6 fw-medium text-capitalize">Peter A. Barber</div>
                //                                 </div>
                //                                 <div className="col-md-4 middle-section text-md-center">
                //                                     <div className="interaction-status text-white fs-6 fw-medium text-capitalize">Total Incentive: ₹10,000</div>
                //                                 </div>
                //                                 <div className="col-md-4 right-section">
                //                                 </div>
                //                             </div>
                //                         </CustomToggle>
                //                     </Card.Header>
                //                     <Accordion.Collapse eventKey="0">
                //                         <Card.Body className="p-3">
                //                             <div className="row g-3 mb-4">
                //                                 <div className="col-md-7 col-xl-8">
                //                                     <div className="info-box">
                //                                         <div className="client-name text-white fs-4 fw-semibold text-capitalize lh-sm">Peter A. Barber</div>
                //                                         <div className="client-company text-light text-opacity-50 fs-14 text-capitalize mb-2">
                //                                             <span className='client-company'>ABC Technology Pvt. Ltd.</span>
                //                                             <span className='client-country fs-14'>United States</span>
                //                                         </div>
                //                                         <div className="fs-6 text-white text-capitalize d-flex flex-wrap gap-2">
                //                                             <span className='work-type'>New Full-Time</span> |
                //                                             <span className='text-uppercase'>SERV29044404</span> |
                //                                             <span>Role</span>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                                 <div className="col-md-5 col-xl-4">
                //                                     <div className="info-box">
                //                                         <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">Simran Gupta</div>
                //                                         <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">simrangupta@companyemail.com</div>
                //                                         <div className="fs-6 text-white text-capitalize">
                //                                             <span className='resource-designation'>Front End</span>
                //                                             <span className='total-assigned-hours text-primary fw-semibold ms-3'>12 hrs / 100 hrs</span>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                             </div>
                //                             <div className="info-box fs-6 mb-3">
                //                                 <div className="label text-warning mb-1">Reason</div>
                //                                 <div className="reason-description text-white fs-14">
                //                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                //                                     dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                //                                     It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                //                                 </div>
                //                             </div>
                //                         </Card.Body>
                //                     </Accordion.Collapse>
                //                 </div>
                //                 <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                //                     <Card.Header className="position-relative p-3" style={{ backgroundColor: '#1C5138' }}>
                //                         <CustomToggle eventKey="1">
                //                             <div className="row gx-2 gy-0">
                //                                 <div className="col-md-4 left-section">
                //                                     <div className="client-name text-white fs-6 fw-medium text-capitalize">Peter A. Barber</div>
                //                                 </div>
                //                                 <div className="col-md-4 middle-section text-md-center">
                //                                     <div className="interaction-status text-white fs-6 fw-medium text-capitalize">Total Incentive: ₹10,000</div>
                //                                 </div>
                //                                 <div className="col-md-4 right-section">
                //                                 </div>
                //                             </div>
                //                         </CustomToggle>
                //                     </Card.Header>
                //                     <Accordion.Collapse eventKey="1">
                //                         <Card.Body className="p-3">
                //                             <div className="row g-3 mb-4">
                //                                 <div className="col-md-7 col-xl-8">
                //                                     <div className="info-box">
                //                                         <div className="client-name text-white fs-4 fw-semibold text-capitalize lh-sm">Peter A. Barber</div>
                //                                         <div className="client-company text-light text-opacity-50 fs-14 text-capitalize mb-2">
                //                                             <span className='client-company'>ABC Technology Pvt. Ltd.</span>
                //                                             <span className='client-country fs-14'>United States</span>
                //                                         </div>
                //                                         <div className="fs-6 text-white text-capitalize d-flex flex-wrap gap-2">
                //                                             <span className='work-type'>New Full-Time</span> |
                //                                             <span className='text-uppercase'>SERV29044404</span> |
                //                                             <span>Role</span>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                                 <div className="col-md-5 col-xl-4">
                //                                     <div className="info-box">
                //                                         <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">Simran Gupta</div>
                //                                         <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">simrangupta@companyemail.com</div>
                //                                         <div className="fs-6 text-white text-capitalize">
                //                                             <span className='resource-designation'>Front End</span>
                //                                             <span className='total-assigned-hours text-primary fw-semibold ms-3'>12 hrs / 100 hrs</span>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                             </div>
                //                             <div className="info-box fs-6 mb-4">
                //                                 <div className="label text-warning mb-1">Communication Overview</div>
                //                                 <div className="text-white d-flex flex-wrap gap-2 text-capitalize">
                //                                     <span className='comm-type'>Verbal</span> |
                //                                     <span className='comm-mode'>Video Call</span> |
                //                                     <span className='comm-palteform'>Teams</span> |
                //                                     <span className='comm-duration'>15 Minutes</span>
                //                                 </div>
                //                             </div>
                //                             <div className="info-box fs-6 mb-4">
                //                                 <div className="label text-warning mb-1">Feedback</div>
                //                                 <div className="feedback-type text-white d-flex flex-wrap gap-2 text-capitalize mb-2">
                //                                     <span className='feedback-type text-green'>Positive</span> |
                //                                     {/* <span className='feedback-type text-danger'>Negative</span> | */}
                //                                     <span className='resource-name text-white'>Simran Gupta</span>
                //                                 </div>
                //                                 <div className="feedback-description text-white fs-14">
                //                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                //                                     dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                //                                     It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                //                                 </div>
                //                             </div>
                //                             <div className="info-box mb-4">
                //                                 <div className="label fs-14 text-warning mb-1">Challenges or Concerns</div>
                //                                 <div className="feedback-description text-white fs-14">
                //                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                //                                     dummy text ever since the 1500s.
                //                                 </div>
                //                             </div>
                //                             <div className="info-box mb-3">
                //                                 <div className="label fs-14 text-warning mb-1">Follow-up</div>
                //                                 <div className="value text-green text-capitalize mb-1">Yes</div>
                //                                 <div className="feedback-description text-white fs-14">
                //                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                //                                     dummy text ever since the 1500s.
                //                                 </div>
                //                             </div>
                //                         </Card.Body>
                //                     </Accordion.Collapse>
                //                 </div>
                //                 <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                //                     <Card.Header className="position-relative p-3" style={{ backgroundColor: '#7D2930' }}>
                //                         <CustomToggle eventKey="2">
                //                             <div className="row gx-2 gy-0">
                //                                 <div className="col-md-4 left-section">
                //                                     <div className="client-name text-white fs-6 fw-medium text-capitalize">Peter A. Barber</div>
                //                                 </div>
                //                                 <div className="col-md-4 middle-section text-md-center">
                //                                     <div className="interaction-status text-white fs-6 fw-medium text-capitalize">Total Incentive: ₹10,000</div>
                //                                 </div>
                //                                 <div className="col-md-4 right-section">
                //                                 </div>
                //                             </div>
                //                         </CustomToggle>
                //                     </Card.Header>
                //                     <Accordion.Collapse eventKey="2">
                //                         <Card.Body className="p-3">
                //                             <div className="row g-3 mb-4">
                //                                 <div className="col-md-7 col-xl-8">
                //                                     <div className="info-box">
                //                                         <div className="client-name text-white fs-4 fw-semibold text-capitalize lh-sm">Peter A. Barber</div>
                //                                         <div className="client-company text-light text-opacity-50 fs-14 text-capitalize mb-2">
                //                                             <span className='client-company'>ABC Technology Pvt. Ltd.</span>
                //                                             <span className='client-country fs-14'>United States</span>
                //                                         </div>
                //                                         <div className="fs-6 text-white text-capitalize d-flex flex-wrap gap-2">
                //                                             <span className='work-type'>New Full-Time</span> |
                //                                             <span className='text-uppercase'>SERV29044404</span> |
                //                                             <span>Role</span>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                                 <div className="col-md-5 col-xl-4">
                //                                     <div className="info-box">
                //                                         <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">Simran Gupta</div>
                //                                         <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">simrangupta@companyemail.com</div>
                //                                         <div className="fs-6 text-white text-capitalize">
                //                                             <span className='resource-designation'>Front End</span>
                //                                             <span className='total-assigned-hours text-primary fw-semibold ms-3'>12 hrs / 100 hrs</span>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                             </div>
                //                             <div className="info-box fs-6 mb-3">
                //                                 <div className="label text-warning mb-1">Reason</div>
                //                                 <div className="reason-description text-white fs-14">
                //                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                //                                     dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                //                                     It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                //                                 </div>
                //                             </div>
                //                         </Card.Body>
                //                     </Accordion.Collapse>
                //                 </div>
                //                 <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                //                     <Card.Header className="position-relative p-3" style={{ backgroundColor: '#1C5138' }}>
                //                         <CustomToggle eventKey="3">
                //                             <div className="row gx-2 gy-0">
                //                                 <div className="col-md-4 left-section">
                //                                     <div className="client-name text-white fs-6 fw-medium text-capitalize">Peter A. Barber</div>
                //                                 </div>
                //                                 <div className="col-md-4 middle-section text-md-center">
                //                                     <div className="interaction-status text-white fs-6 fw-medium text-capitalize">Total Incentive: ₹10,000</div>
                //                                 </div>
                //                                 <div className="col-md-4 right-section">
                //                                 </div>
                //                             </div>
                //                         </CustomToggle>
                //                     </Card.Header>
                //                     <Accordion.Collapse eventKey="3">
                //                         <Card.Body className="p-3">
                //                             <div className="row g-3 mb-4">
                //                                 <div className="col-md-7 col-xl-8">
                //                                     <div className="info-box">
                //                                         <div className="client-name text-white fs-4 fw-semibold text-capitalize lh-sm">Peter A. Barber</div>
                //                                         <div className="client-company text-light text-opacity-50 fs-14 text-capitalize mb-2">
                //                                             <span className='client-company'>ABC Technology Pvt. Ltd.</span>
                //                                             <span className='client-country fs-14'>United States</span>
                //                                         </div>
                //                                         <div className="fs-6 text-white text-capitalize d-flex flex-wrap gap-2">
                //                                             <span className='work-type'>New Full-Time</span> |
                //                                             <span className='text-uppercase'>SERV29044404</span> |
                //                                             <span>Role</span>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                                 <div className="col-md-5 col-xl-4">
                //                                     <div className="info-box">
                //                                         <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">Simran Gupta</div>
                //                                         <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">simrangupta@companyemail.com</div>
                //                                         <div className="fs-6 text-white text-capitalize">
                //                                             <span className='resource-designation'>Front End</span>
                //                                             <span className='total-assigned-hours text-primary fw-semibold ms-3'>12 hrs / 100 hrs</span>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                             </div>
                //                             <div className="info-box fs-6 mb-4">
                //                                 <div className="label text-warning mb-1">Communication Overview</div>
                //                                 <div className="text-white d-flex flex-wrap gap-2 text-capitalize">
                //                                     <span className='comm-type'>Verbal</span> |
                //                                     <span className='comm-mode'>Video Call</span> |
                //                                     <span className='comm-palteform'>Teams</span> |
                //                                     <span className='comm-duration'>15 Minutes</span>
                //                                 </div>
                //                             </div>
                //                             <div className="info-box fs-6 mb-4">
                //                                 <div className="label text-warning mb-1">Feedback</div>
                //                                 <div className="feedback-type text-white d-flex flex-wrap gap-2 text-capitalize mb-2">
                //                                     <span className='feedback-type text-green'>Positive</span> |
                //                                     {/* <span className='feedback-type text-danger'>Negative</span> | */}
                //                                     <span className='resource-name text-white'>Simran Gupta</span>
                //                                 </div>
                //                                 <div className="feedback-description text-white fs-14">
                //                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                //                                     dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                //                                     It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                //                                 </div>
                //                             </div>
                //                             <div className="info-box mb-4">
                //                                 <div className="label fs-14 text-warning mb-1">Challenges or Concerns</div>
                //                                 <div className="feedback-description text-white fs-14">
                //                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                //                                     dummy text ever since the 1500s.
                //                                 </div>
                //                             </div>
                //                             <div className="info-box mb-3">
                //                                 <div className="label fs-14 text-warning mb-1">Follow-up</div>
                //                                 <div className="value text-green text-capitalize mb-1">Yes</div>
                //                                 <div className="feedback-description text-white fs-14">
                //                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                //                                     dummy text ever since the 1500s.
                //                                 </div>
                //                             </div>
                //                         </Card.Body>
                //                     </Accordion.Collapse>
                //                 </div>
                //                 <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                //                     <Card.Header className="position-relative p-3" style={{ backgroundColor: '#1C5138' }}>
                //                         <CustomToggle eventKey="4">
                //                             <div className="row gx-2 gy-0">
                //                                 <div className="col-md-4 left-section">
                //                                     <div className="client-name text-white fs-6 fw-medium text-capitalize">Peter A. Barber</div>
                //                                 </div>
                //                                 <div className="col-md-4 middle-section text-md-center">
                //                                     <div className="interaction-status text-white fs-6 fw-medium text-capitalize">Total Incentive: ₹10,000</div>
                //                                 </div>
                //                                 <div className="col-md-4 right-section">
                //                                 </div>
                //                             </div>
                //                         </CustomToggle>
                //                     </Card.Header>
                //                     <Accordion.Collapse eventKey="4">
                //                         <Card.Body className="p-3">
                //                             <div className="row g-3 mb-4">
                //                                 <div className="col-md-7 col-xl-8">
                //                                     <div className="info-box">
                //                                         <div className="client-name text-white fs-4 fw-semibold text-capitalize lh-sm">Peter A. Barber</div>
                //                                         <div className="client-company text-light text-opacity-50 fs-14 text-capitalize mb-2">
                //                                             <span className='client-company'>ABC Technology Pvt. Ltd.</span>
                //                                             <span className='client-country fs-14'>United States</span>
                //                                         </div>
                //                                         <div className="fs-6 text-white text-capitalize d-flex flex-wrap gap-2">
                //                                             <span className='work-type'>New Full-Time</span> |
                //                                             <span className='text-uppercase'>SERV29044404</span> |
                //                                             <span>Role</span>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                                 <div className="col-md-5 col-xl-4">
                //                                     <div className="info-box">
                //                                         <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">Simran Gupta</div>
                //                                         <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">simrangupta@companyemail.com</div>
                //                                         <div className="fs-6 text-white text-capitalize">
                //                                             <span className='resource-designation'>Front End</span>
                //                                             <span className='total-assigned-hours text-primary fw-semibold ms-3'>12 hrs / 100 hrs</span>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                             </div>
                //                             <div className="info-box fs-6 mb-4">
                //                                 <div className="label text-warning mb-1">Communication Overview</div>
                //                                 <div className="text-white d-flex flex-wrap gap-2 text-capitalize">
                //                                     <span className='comm-type'>Verbal</span> |
                //                                     <span className='comm-mode'>Video Call</span> |
                //                                     <span className='comm-palteform'>Teams</span> |
                //                                     <span className='comm-duration'>15 Minutes</span>
                //                                 </div>
                //                             </div>
                //                             <div className="info-box fs-6 mb-4">
                //                                 <div className="label text-warning mb-1">Feedback</div>
                //                                 <div className="feedback-type text-white d-flex flex-wrap gap-2 text-capitalize mb-2">
                //                                     <span className='feedback-type text-green'>Positive</span> |
                //                                     {/* <span className='feedback-type text-danger'>Negative</span> | */}
                //                                     <span className='resource-name text-white'>Simran Gupta</span>
                //                                 </div>
                //                                 <div className="feedback-description text-white fs-14">
                //                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                //                                     dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                //                                     It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                //                                 </div>
                //                             </div>
                //                             <div className="info-box mb-4">
                //                                 <div className="label fs-14 text-warning mb-1">Challenges or Concerns</div>
                //                                 <div className="feedback-description text-white fs-14">
                //                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                //                                     dummy text ever since the 1500s.
                //                                 </div>
                //                             </div>
                //                             <div className="info-box mb-3">
                //                                 <div className="label fs-14 text-warning mb-1">Follow-up</div>
                //                                 <div className="value text-green text-capitalize mb-1">Yes</div>
                //                                 <div className="feedback-description text-white fs-14">
                //                                     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                //                                     dummy text ever since the 1500s.
                //                                 </div>
                //                             </div>
                //                         </Card.Body>
                //                     </Accordion.Collapse>
                //                 </div>
                //             </Accordion>
                //         ) : (
                //             <div className="content-wrapper h-100 border border-secondary rounded-3 d-flex flex-column justify-content-center flex-fill text-center overflow-auto p-3">
                //                 <div className="icon text-center mb-3 mb-md-4">
                //                     <i className="bi bi-exclamation-octagon-fill fs-1 lh-1" style={{ color: '#acaa54' }}></i>
                //                 </div>
                //                 <div className="description text-light fw-medium text-center lh-sm mb-4">
                //                     No work update submitted <span style={{ color: '#e6ef13' }}>today</span>. SDM incentives require daily updates for each billing.<br />
                //                     Incentives will be released after Sheela receives all updates for the billing hours.
                //                 </div>
                //                 <div className="btn-wrapper text-center">
                //                     <Button variant="outline-warning" className="px-4"><i className="bi bi-plus-circle-fill"></i></Button>
                //                 </div>
                //             </div>
                //         )}
                //     </div>
                // );
            },
        },
    },
});

export default CustomDayViewPlugin;
